import React from 'react'
import './Cloud.scss';

const Cloud = (props) => {
  const {direction, duration, vertical } = props;

  return (
    <div className='cloudContainer' style={{
      animationDirection: direction,
      animationDuration: duration,
      animationName: vertical ? 'moveVertical' : 'moveHorizontal'
    }}>
      <div className='cloud cloudBase' style={{
        borderRadius: `50px`
      }}></div>
      <div className='cloud cloudLeft' style={{
        borderRadius: `50%`
      }}></div>
      <div className='cloud cloudRight' style={{
        borderRadius: `50%`
      }}></div>
    </div>
  )
}

export default Cloud