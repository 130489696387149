import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.scss';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({setIsToastOpen, message, isSuccess}) => {
  const form = useRef();
  const isSent = useRef(false);
  const [isVerified, setIsVerified] = useState(false);
  const handleChange = (value) => {
    console.log('capture value:', value);
    setIsVerified(true);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY )
      .then((result) => {
          console.log(result.text);
          if (result.status === 200) {
            message.current = "Message sent";
            isSuccess.current = true;
            isSent.current = true;
            setIsToastOpen(true);
          }
      }, (error) => {
          console.log(error.text);
          message.current = "Error: message not sent";
          isSuccess.current = false;
          setIsToastOpen(true);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} className='contactForm'>
      {
        isSent.current ? 
        <div className='isSentContainer'>
          <h2>Thank you!</h2>
          <p>I'll be in touch shortly</p>
        </div> :
        <>
          <label>Name</label>
          <input type="text" name="from_name" />
          <label>Email</label>
          <input type="email" name="from_email" />
          <label>Message</label>
          <textarea name="message" />
          <button disabled={!isVerified} type="submit">Send</button>
        </>
      }
       {
        !isSent.current && 
        <div className='captureContainer'>
          <ReCAPTCHA
            theme="dark"
            size='compact'
            sitekey={process.env.REACT_APP_CAPTURE_SITE_KEY}
            onChange={handleChange}
          />
        </div>
      }
    </form>
  );
};

export default ContactForm