import React from "react";
import "./Hill.scss";

const Hill = (props) => {
  const { left, bottom, top, width, height, type1, fill, right, zindex, flip } = props;

  return (
    <div
      className="hillContainer"
      style={{
        top: top,
        bottom: bottom,
        left: left,
        right,
        width: width,
        height: height,
        zIndex: zindex
      }}
    >
      <div className={flip ? 'hill flip' : 'hill'}>
        {
          type1 ?
          <svg
            data-name="Layer 1"
            viewBox="0 0 900 369"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M137 161.5C196.5 138.528 196.5 150.5 303.5 183.076C410.5 215.651 373.5 0.0755615 441.5 0.0755615C534.5 0.0755615 502.5 225.576 565 225.576C627.5 225.576 629.5 104.076 740.5 104.076C851.5 104.076 899.5 368.5 899.5 368.5H0.5C0.5 368.5 77.5 184.472 137 161.5Z"
              fill={`${fill}`}
            />
          </svg> :
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 900 293"
            preserveAspectRatio="none"
          >
            <path
              d="M46 50.4242C100 -24.0001 231 -7.65102 290 50.4245C349 108.5 361.5 185.5 424 185.5C486.5 185.5 495.5 137 606.5 137C717.5 137 900 292.424 900 292.424H0.999984C0.999984 292.424 -7.99991 124.849 46 50.4242Z"
              fill={`${fill}`}
            ></path>
          </svg>
        }
      </div>
    </div>
  );
};

export default Hill;
