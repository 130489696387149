import React from 'react'
import { ReactComponent as Sunray } from '../../assets/sun-ray.svg';
import variables from '../../_variables.scss';
import './Sun.scss';

const Sun = () => {
  return (
    <div className='sun'>
      
      {/* bottom */}
      <Sunray className='sunray' id='sunray1' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        left: '0',
        right: '0',
        animation: 'rayExpand1 2s ease-in-out infinite alternate'
      }}/>
      
      {/* top */}
      <Sunray className='sunray' id='sunray2' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        left: '0',
        right: '0px',
        animation: 'rayExpand2 2s ease-in-out infinite alternate'
      }}/>

      {/* right 90deg */}
      <Sunray className='sunray' id='sunray3' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        bottom: '0',
        top: '0',
        animation: 'rayExpand3 2s ease-in-out infinite alternate'
      }}/>

      {/* left 90deg */}
      <Sunray className='sunray' id='sunray4' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        bottom: '0',
        top: '0',
        animation: 'rayExpand4 2s ease-in-out infinite alternate'
      }}/>

      {/* right 45deg */}
      <Sunray className='sunray' id='sunray5' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        animation: 'rayExpand5 2s ease-in-out infinite alternate-reverse'
      }}/>

      {/* right 135deg */}
      <Sunray className='sunray' id='sunray6' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        animation: 'rayExpand6 2s ease-in-out infinite alternate-reverse'
      }}/>

      {/* left 45deg */}
      <Sunray className='sunray' id='sunray7' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        animation: 'rayExpand7 2s ease-in-out infinite alternate-reverse'
      }}/>

       {/* left 135deg */}
       <Sunray className='sunray' id='sunray8' fill={variables.orangecolour} stroke={variables.redcolour} width="50%" height="50%" style={{
        animation: 'rayExpand8 2s ease-in-out infinite alternate-reverse'
      }}/>
    </div>
  )
}

export default Sun