import { useState } from 'react';
import './Blind.scss';
import { ReactComponent as HandleDown } from '../../assets/blind-handle-up.svg';
import { ReactComponent as HandleUp } from '../../assets/blind-handle-down.svg';
import variables from '../../_variables.scss';
import { useNavigate } from "react-router-dom";

const Blind = (props) => {
  const {children} = props;
  const [isHandleHover, setIsHandleHover] = useState(false); 
  const { isBlindDown, setIsBlindDown} = props;
  const navigate = useNavigate();
  const handleClick= () => {
    setIsBlindDown(!isBlindDown);
    setIsHandleHover(false);
    isBlindDown ? navigate("/about") : navigate("/")
    
  }

  return (
    <div id="blind" className="container" style={{
      height: isBlindDown ? "5vh" : "90vh",
      animation: isBlindDown ? "moveBlindDown 0.7s ease-in-out forwards" : "moveBlindUp 0.7s ease-in-out forwards"
    }}>
      <div className='dotContainer' style={{}}>
        {children}
      </div>
      <div className='blindEnd'></div>
      {
        isBlindDown ?
        <HandleDown className="handle" fill={isHandleHover ? variables.blackcolour : variables.orangecolour} height="37px" style={{bottom: "-37px"}} onMouseOver={() => setIsHandleHover(true)} onMouseOut={() => setIsHandleHover(false)} onClick={handleClick} /> :
        <HandleUp className="handle" fill={isHandleHover ? variables.blackcolour : variables.orangecolour} height="37px" style={{bottom: "-37px"}} onMouseOver={() => setIsHandleHover(true)} onMouseOut={() => setIsHandleHover(false)} onClick={handleClick} /> 
      }      
    </div>
  )
}

export default Blind