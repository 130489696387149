import React from 'react'
import '../pages.scss';
import profileImg from '../../assets/profile.png';
import Skills from '../../components/Skills/Skills';
import './About.scss';
import variables from '../../_variables.scss';
import Sun from '../../components/Sun/Sun';
import Cloud from '../../components/Cloud/Cloud';
import Hill from '../../components/Hill/Hill';
import Boat from '../../components/Boat/Boat';
import { useInView } from 'react-intersection-observer';

const About = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
    rootMargin: '-50px 0px',
  });

  const { ref: text1Ref, inView: text1InView } = useInView({
    triggerOnce: true,
    threshold: 1,
    rootMargin: '-50px 0px',
  });

  const { ref: text2Ref, inView: text2InView } = useInView({
    triggerOnce: true,
    threshold: 1,
    rootMargin: '-50px 0px',
  });

  const { ref: text3Ref, inView: text3InView } = useInView({
    triggerOnce: true,
    threshold: 1,
    rootMargin: '-50px 0px',
  });


  return (
    <div className='sectionContainer'>
      <div className='aboutLanding'> 
        <div className='imageContainer'>
          <Sun />
          <div id='cloud1'>
            <Cloud direction='alternate' duration='6s' vertical={true} />
          </div>
          <div id='cloud2'>
            <Cloud direction='reverse' duration='10s' vertical={false} />
          </div>
          <img className='profileImage' src={profileImg} alt="profile headshot" />
          <Hill width="55%" height="33%" bottom="-1%" left="0" type1={false} fill={`${variables.greencolour}`} zindex='2' />

          {/* mirror */}
          <Hill width="55%" height="33%" top="101%" left="0" type1={false} fill={variables.blackcolour} zindex='4' flip={true} />

          <Hill width="50%" height="22%" bottom="-1%" right="0%" type1={true} fill={`${variables.darkgreencolour}`} zindex='1'/>

          {/* mirror */}
          <Hill width="50%" height="22%" top="101%" right="0%" type1={true} fill={variables.blackcolour} zindex='3' flip={true} />
        </div>

        <Boat />
      </div>

      <section className={`textContainer waterBackground`}>
        <h2
          className={`statement-name statement ${titleInView ? 'showTitle' : 'hiddenSlideLeft'}`}><span style={{fontSize: "2.5em"}}>Hi</span>, my name is Jojo.</h2>
      </section>

      <section className='textContainer'>
        <h2 ref={titleRef} className={`statement ${titleInView ? 'showTitle' : 'hiddenSlideRight'}`} style={{
          marginTop: '2rem'
        }}>I am a <span style={{fontWeight: "700"}}>front-end</span> <span style={{fontWeight: "700", color: variables.bluecolour}}>developer</span> <span style={{display: 'inline-block'}}>and <span style={{fontStyle: "italic"}}>web development <span style={{color: variables.pinkcolour}}>tutor</span>.</span></span></h2>

        <p ref={text1Ref} className={text1InView ? 'show' : 'hiddenSlide'} style={{margin: '20% 0 35px 0% '}}>I am a UK based developer from Brighton and based in Bristol. My passion is in creating clean and beautiful code with a keen interest in UI design.</p>

        <p ref={text2Ref} className={text2InView ? 'show' : 'hiddenSlide'} style={{margin: '10% 0% 75px 0% '}}>As a technically minded mechanical engineering graduate with a desire to be creative, I started my journey in software like many modern day junior programmers via an intensive software development bootcamp.</p>

        <p ref={text3Ref} className={text3InView ? 'show' : 'hiddenSlide'}  style={{margin: '10% 0 100px 0% '}}>Since completing the course I have worked as a junior front-end developer, web development tutor and freelancer!</p>
      </section>

      <Skills />
    </div>
  )
}

export default About