import React from "react";
import "./Skills.scss";
import JSIcon from "../../assets/icons/js-icon.svg";
import CSSIcon from "../../assets/icons/css.svg";
import sassIcon from "../../assets/icons/sass.svg";
import HTMLIcon from "../../assets/icons/html.svg";
import reactIcon from "../../assets/icons/react.svg";
import angularIcon from "../../assets/icons/angular.svg";
import nodeIcon from "../../assets/icons/node.svg";
import nextIcon from "../../assets/icons/next.svg";
import TSIcon from "../../assets/icons/typescript.svg";
import awsIcon from "../../assets/icons/aws.svg";
import shopifyIcon from "../../assets/icons/shopify.svg";
import githubIcon from "../../assets/icons/github.svg";
import SkillGrid from "./SkillGrid/SkillGrid";

const Skills = () => {
  return (
    <section className="skillSection">
      <h2
        className="title"
        style={{
          padding: "0 2rem",
          margin: '6rem 0'
        }}
      >
        MY TECH
      </h2>
      <div className="badgeGridContainer">
        <SkillGrid
          items={[
            {
              icon: reactIcon,
              altText: "React JS icon",
              text: "React JS",
            },
            {
              icon: JSIcon,
              altText: "JavaScript icon",
              text: "JavaScript ES6+",
            },
            {
              icon: HTMLIcon,
              altText: "HTML 5 icon",
              text: "HTML 5",
            },
            {
              icon: CSSIcon,
              altText: "CSS 3 icon",
              text: "CSS 3",
            },
            {
              icon: sassIcon,
              altText: "Sass/scss icon",
              text: "Sass",
            },
            {
              icon: nodeIcon,
              altText: "Node icon",
              text: "Node JS",
            },
            {
              icon: awsIcon,
              altText: "AWS icon",
              text: "DynamoDb, Lambda, Amplify, API Gateway, Cognito",
            },
            {
              icon: TSIcon,
              altText: "Typescript icon",
              text: "TypeScript",
            },
            {
              icon: nextIcon,
              altText: "Next icon",
              text: "Next JS",
            },
            {
              icon: angularIcon,
              altText: "Angular icon",
              text: "Angular",
            },
            {
              icon: shopifyIcon,
              altText: "Shopify icon",
              text: "Shopify/Liquid",
            },
            {
              icon: githubIcon,
              altText: "GitHub icon",
              text: "GitHub",
            },
          ]}
        />
      </div>
    </section>
  );
};

export default Skills;
