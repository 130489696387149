import React from "react";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import "./Toast.scss";

// input -> isOpen (tre/false), message
const Toast = ({ isOpen, setIsOpen, message, isSuccess }) => {
  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`toastContainer ${isSuccess.current ? "toastSuccess" : "toastError"}`}
      style={{
        display: isOpen ? "grid" : "none",
      }}
    >
      <div className="crossContainer" onClick={handleClick}>
        <Cross className="cross" />
      </div>
      <p>{message}</p>
    </div>
  );
};

export default Toast;
