import React, { useState } from "react";
import "./SkillBadge.scss";
import { useInView } from 'react-intersection-observer';

const SkillBadge = (props) => {
  const { icon, altText, text } = props;
  const [isHover, setIsHover] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.7,
    rootMargin: '-50px 0px',
  });

  return (
    <div
      ref={ref}
      className={inView ? 'show badge' : 'hiddenSlideLarge badge'}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <div
        className="textContainer"
        style={{
          animation: isHover ? "appear 1s ease-out forwards" : "",
        }}
      >
        <p>{text}</p>
      </div>
        <img
          src={icon}
          alt={altText}
          style={{
            animation: isHover ? "shrink 0.5s ease-out forwards" : "",
          }}
        />
    </div>
  );
};

export default SkillBadge;
