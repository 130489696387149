import React, { useRef, useState } from "react";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg";
import variables from "../../_variables.scss";
import "./Contact.scss";
import ContactForm from "../../components/ContactForm/ContactForm";
import Toast from "../../components/Toast/Toast";

const Contact = () => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const message = useRef();
  const isSuccess = useRef();

  return (
    <div className="contactContainer">
      <h2 className="contactTitle">
        {["C", "O", "N", "T", "A", "C", "T"].map((letter, index) => {
          return <span key={letter + index}>{letter}</span>;
        })}
      </h2>
      <div className="floatingContainer">
        <a
          href="https://www.linkedin.com/in/jojo-dance/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon
            fill={variables.deepbluecolour}
            className="linkedInIcon"
          />
        </a>
        <div className="shadow"></div>
      </div>
      <ContactForm         
        setIsToastOpen={setIsToastOpen}
        message={message}
        isSuccess={isSuccess}
      />
      <Toast
        isOpen={isToastOpen}
        setIsOpen={setIsToastOpen}
        message={message.current}
        isSuccess={isSuccess}
      />
    </div>
  );
};

export default Contact;
