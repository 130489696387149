import React from 'react';
import './LandingTitle.scss';
import variables from '../../_variables.scss';

const LandingTitle = () => {
  const delayInc = 0.1;
  const delay = 1;

  return (
    <header className='titleContainer'>
      <h1 className='name' style={{
        marginBottom: '0rem'
      }}>
         <span style={{
          display: 'inline-block'
        }}>
          {
            ['J', 'O', 'J', 'O'].map((letter, index) => {
              return (
                <span key={`${letter}${index}`} className='flip' style={{animationDelay: `${delay + (delayInc * (index+1))}s`}}>{letter}</span>
              )
            })
          }
        </span>
        <span style={{paddingLeft: '1rem'}}></span>
        <span style={{
          display: 'inline-block'
        }}>
          {
            ['D', 'A', 'N', 'C', 'E'].map((letter, index) => {
              return (
                <span key={`${letter}${index}`} className='flip' style={{animationDelay: `${delay + (delayInc * (index+5))}s`}}>{letter}</span>
              )
            })
          }
        </span>
      </h1>
      <h1 className='main' style={{
        fontFamily: variables.fontmain,
      }}>FRONT-END <br/> DEVELOPER</h1>
      <h1 className='submain' style={{
        marginTop: '0.75rem'
      }}>        
        <span  className='flip' style={{animationDelay: `${delay + (delayInc * 10)}s`}}>{`&`}</span>
        <span style={{paddingLeft: '1rem'}}></span>
        <span style={{
          display: 'inline-block'
        }}>
          {
            ['W', 'E', 'B'].map((letter, index) => {
              return (
                <span key={`${letter}${index}`} className='flip' style={{animationDelay: `${delay + (delayInc * (index+12))}s`}}>{letter}</span>
              )
            })
          }
        </span>
        <span style={{paddingLeft: '1rem'}}></span>
        <span style={{
          display: 'inline-block'
        }}>
          {
            ['D', 'E', 'V', 'E', 'L', 'O', 'P', 'M', 'E', 'N', 'T'].map((letter, index) => {
              return (
                <span key={`${letter}${index}`} className='flip' style={{animationDelay: `${delay + (delayInc * (index+15))}s`}}>{letter}</span>
              )
            })
          }
        </span>
        <span style={{paddingLeft: '1rem'}}></span>
        <span style={{
          display: 'inline-block'
        }}>
          {
            ['T', 'U', 'T', 'O', 'R'].map((letter, index) => {
              return (
                <span key={`${letter}${index}`} className='flip' style={{animationDelay: `${delay + (delayInc * (index+25))}s`}}>{letter}</span>
              )
            })
          }
        </span>
      </h1>
    </header>
  )
}

export default LandingTitle