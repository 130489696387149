import React, { useState } from 'react'
import './MainPage.scss';
import Nav from '../../components/Nav/Nav';
import { Outlet } from "react-router-dom";
import Landing from '../../components/Landing/Landing';

const MainPage = () => {
  const [isBlindDown, setIsBlindDown] = useState(true);

  return (
    <main className='mainContainer'>
      <Landing isBlindDown={isBlindDown} setIsBlindDown={setIsBlindDown} />
      <h3 className="icon">JoJo <br/> Dance</h3>
      <Nav />
      <section id='content'>
        {
          !isBlindDown && <Outlet />
        }
      </section>
    </main>
  )
}

export default MainPage