import React from 'react'
import Blind from '../Blind/Blind';
import LandingTitle from '../LandingTitle/LandingTitle';

const Landing = (props) => {
  const { isBlindDown, setIsBlindDown } = props;

  return (
    <Blind isBlindDown={isBlindDown} setIsBlindDown={setIsBlindDown} >
      { isBlindDown && <LandingTitle />}
    </Blind>
  )
}

export default Landing