import React from 'react';
import './SkillGrid.scss';
import SkillBadge from '../SkillBadge/SkillBadge';

const SkillGrid = ({items}) => {

  return (
    <div className='badgeGrid'>
      {
        items.map((item, index) => {
          return (
            <SkillBadge 
              key={index + item.text[0]}
              icon={item.icon} 
              altText={item.altText} 
              text={item.text}
            />
          )
        })
      }
    </div>
  )
}

export default SkillGrid