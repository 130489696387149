import React from 'react'
import './NavIcon.scss';

const NavIcon = ({isOpen}) => {
  return (
    <div className='navIconContainer'>
      <div className='bar' style={{
        animation: isOpen ? 'topArrow 0.4s ease-in-out forwards, colourTransition 1s ease-in-out forwards' : 'topArrowReverse 0.4s ease-in-out forwards, colourTransition 1s ease-in-out reverse forwards'
      }}></div>
      <div className='bar' style={{
        animation: isOpen ? 'colourTransition 1s ease-in-out forwards' : 'colourTransition 1s ease-in-out reverse forwards'
      }}></div>
      <div className='bar' style={{
         animation: isOpen ? 'bottomArrow 0.4s ease-in-out forwards, colourTransition 1s ease-in-out forwards' : 'bottomArrowReverse 0.4s ease-in-out forwards, colourTransition 1s ease-in-out reverse forwards'
      }}></div>
    </div>
  )
}

export default NavIcon