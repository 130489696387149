import React, { useState } from "react";
import "../pages.scss";
import "./Projects.scss";
import variables from "../../_variables.scss";
import ProjectTile from "../../components/ProjectTile/ProjectTile";

const Projects = () => {
  const [selected, setSelected] = useState(null);

  return (
    <section className="sectionContainer">
      <div className="projectsTitleContainer">
        <h2
          style={{
            textAlign: "left",
            animation: "slideInTitleLeft 1s ease-in-out forwards",
          }}
        >
          PERSONAL
        </h2>
        <h2
          style={{
            textAlign: "right",
            animation: "slideInTitleRight 1s ease-in-out forwards",
          }}
        >
          PROJECTS
        </h2>
      </div>

      <div className="projectTileGrid">
        <ProjectTile
          offsetLeft="10%"
          colour={variables.redcolour}
          selected={selected}
          setSelected={setSelected}
          name="Bángh-Mì"
          about={`Responsive Vietnamese street food website inspired by my love of the cuisine. Features: home section with a custom logo, menu, food ordering UX, about page and events page.`}
          link=" https://banghmiapp123.web.app/"
          github="https://github.com/J-dance/BanhG-mi-app/tree/main/banhg-mi-app"
        />

        <ProjectTile
          selected={selected}
          setSelected={setSelected}
          name="PUNK IPA API"
          about="Built a frontend for a public REST API with a search bar and filter feature for the results."
          link="https://punk-api-c0095.web.app/"
          github="https://github.com/J-dance/Punk-IPA-API"
        />

        <ProjectTile
          selected={selected}
          setSelected={setSelected}
          name="My Portfolio V1"
          about="Old portfolio website built using React, Sass and hosted using Firebase with a long intro animation."
          link="https://j-dance-portfolio.web.app/"
          github="https://github.com/J-dance/My-React_Portfolio"
        />

        <ProjectTile
          selected={selected}
          setSelected={setSelected}
          name="Game: Ready for Beddy!"
          about="My first game built with JavaScript, HTML and Sass. Multi-level grid-based memory game."
          link="https://j-dance.github.io/My-Javescript-Game/"
          github="https://github.com/J-dance/My-Javescript-Game"
        />

        <ProjectTile
          selected={selected}
          setSelected={setSelected}
          name="Calculator"
          about="My first project integrating HTMl, CSS/SCSS and JavaScript. Calculator with basic functionality and multiple displays, using Bootstrap to help style and Cypress for e2e testing."
          link="https://j-dance.github.io/My-Calculator/"
          github="https://github.com/J-dance/My-Calculator"
        />

        <ProjectTile
          selected={selected}
          setSelected={setSelected}
          name="Knowaste MVP"
          about="Bootcamp client React + Firebase project. Features: user and admin portal, select meal options, profile data, leaderboard of user stats, upload features, calculate totals from users data."
          link="https://knowaste-3c92c.web.app/"
          github="https://github.com/J-dance/KnoWaste"
        />
      </div>
    </section>
  );
};

export default Projects;
