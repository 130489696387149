import React from "react";
import './Boat.scss';

const Boat = () => {
  return (
    <div className="boatContainer">
      <svg
        viewBox="0 0 824 1134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_326_28)">
          <path
            d="M20.7773 591H779.223C779.223 591 825.972 791.308 779.223 904.432C666.099 1178.17 133.901 1178.17 20.7773 904.432C-25.9716 791.308 20.7773 591 20.7773 591Z"
            fill="#80531E"
          />
          <path
            d="M23.7548 600.848C24.1544 598.93 24.5015 597.306 24.7854 596H775.215C775.499 597.306 775.846 598.93 776.245 600.848C777.423 606.503 779.057 614.721 780.874 624.941C784.508 645.386 788.868 673.814 791.774 705.748C797.609 769.864 797.479 847.163 774.602 902.522C719.148 1036.71 560.36 1104.74 400 1104.74C239.64 1104.74 80.8516 1036.71 25.3983 902.522C2.52086 847.163 2.39149 769.864 8.22588 705.748C11.1317 673.814 15.4919 645.386 19.1261 624.941C20.9427 614.721 22.5767 606.503 23.7548 600.848Z"
            stroke="#081B23"
            strokeWidth="10"
          />
        </g>
        <rect
          x="374"
          y="103"
          width="53"
          height="494"
          fill="#80531E"
          stroke="black"
          strokeWidth="8"
        />
        <path
          d="M429.205 437.75L429.205 108.66L714.205 273.205L429.205 437.75Z"
          fill="#FEFEFF"
          stroke="black"
          strokeWidth="10"
        />
        <circle
          cx="212"
          cy="793"
          r="97.5"
          fill="#081B23"
          stroke="black"
          strokeWidth="5"
        />
        <circle
          cx="212"
          cy="793"
          r="57.5"
          fill="#D9D9D9"
          stroke="black"
          strokeWidth="5"
        />
        <circle
          cx="589"
          cy="793"
          r="97.5"
          fill="#081B23"
          stroke="black"
          strokeWidth="5"
        />
        <circle
          cx="589"
          cy="793"
          r="57.5"
          fill="#D9D9D9"
          stroke="black"
          strokeWidth="5"
        />
        <defs>
          <filter
            id="filter0_d_326_28"
            x="0"
            y="591"
            width="824"
            height="542.735"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="20" dy="20" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_326_28"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_326_28"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default Boat;
