import React from 'react'
import { NavLink } from "react-router-dom";
import './MyNavLink.scss';

const MyNavLink = ({section, setIsOpen}) => {

  return (
    <div className='linkContainer'>
      <NavLink 
        to={section.toLowerCase()}
        onClick={() => setIsOpen(false)}
        className={(props) => {
          return `${props.isActive ? 'isActive ' : 'notActive'}`;
        }}
        style={{
          lineHeight: '0.74'
        }}
      >{section}</NavLink>
    </div>
  )
}

export default MyNavLink