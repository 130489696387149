import React, { useState } from 'react';
import './Nav.scss';
import MyNavLink from './MyNavLink/MyNavLink';
import NavIcon from './NavIcon/NavIcon';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className='listContainer' style={{
        animation: isOpen ? 'expand-nav 1s ease forwards' : 'minimise-nav 1s ease forwards'
      }}>
        <ul style={{
          animation: isOpen ? 'slideIn 1s ease-in-out forwards' : 'slideOut 1s ease-in-out forwards'
        }}>
          <li>
            <MyNavLink 
              section="About"
              setIsOpen={setIsOpen}
            />
          </li>
          <li>
            <MyNavLink 
              section="Projects"
              setIsOpen={setIsOpen}
            />
          </li>
          <li>
            <MyNavLink 
              section="Contact"
              setIsOpen={setIsOpen}
            />
          </li>
        </ul>
      </div>
      <div className='iconContainer' onClick={handleClick}>
        <NavIcon isOpen={isOpen} />
      </div>
    </nav>
  )
}

export default Nav