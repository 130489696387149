import React, { useState } from "react";
import "./ProjectTile.scss";
import { ReactComponent as Chevron } from "../../assets/icons/chevron.svg";
import variables from "../../_variables.scss";
import { useInView } from 'react-intersection-observer';

const ProjectTile = (props) => {
  const { selected, setSelected, name, about, github, link } = props;
  const [isHover, setIsHover] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.7,
    rootMargin: '-50px 0px',
  });

  const handleClick = () => {
    setSelected((prevState) => {
      if (prevState === name) {
        return false;
      } else {
        return name;
      }
    });
  };

  return (
    <div
      className={inView ? 'show projectTileContainer' : 'hiddenSlideLarge projectTileContainer'}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      ref={ref}
    >
      <h3>{name}</h3>
      <div
        className="content"
        style={{
          transform: selected === name ? "translateX(0%)" : "translateX(100%)",
        }}
      >
        <Chevron
          stroke={isHover ? variables.orangecolour : variables.whitecolour}
          className="tileArrow"
          style={{
            left: "-3.5rem",
            transform: "rotate(90deg)",
          }}
          onClick={handleClick}
        />
        <Chevron
          stroke={isHover ? variables.orangecolour : variables.whitecolour}
          className="tileArrow"
          style={{
            right: "-0.5rem",
            transform: "rotate(-90deg)",
          }}
          onClick={handleClick}
        />

        <p className="tileText">{about}</p>
        <a href={github} target="_blank" rel="noopener noreferrer">
          <button className="projectTileButton">{`<code>`}</button>
        </a>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button className="projectTileButton">{`<view>`}</button>
        </a>
      </div>
    </div>
  );
};

export default ProjectTile;
